<template>
	<div>
		<b-row>
			<b-col cols="12" md="6">
				<b-card title="User Profile">
					<div class="d-flex align-items-center justify-content-between mb-1">
						<div class="customer-title">Nama Lengkap</div>
						<p class="customer-data">{{ customer.name }}</p>
					</div>
					<div class="d-flex align-items-center justify-content-between mb-1">
						<div class="customer-title">Email</div>
						<p class="customer-data">{{ customer.email }}</p>
					</div>
					<div class="d-flex align-items-center justify-content-between mb-1">
						<div class="customer-title">Jenis Kelamin</div>
						<b-badge 
							variant="primary"
							v-if="customer.gender == 'male'"
						>
							<p class="customer-data text-white">Pria</p>
						</b-badge>
						<b-badge 
							variant="danger"
							v-if="customer.gender == 'female'"
						>
							<p class="customer-data text-white">Wanita</p>
						</b-badge>
					</div>
					<div class="d-flex align-items-center justify-content-between mb-1">
						<div class="customer-title">No.Handphone</div>
						<p class="customer-data">{{ customer.phone }}</p>
					</div>
					<div class="d-flex align-items-center justify-content-between mb-1">
						<div class="customer-title">Alamat</div>
						<p class="customer-data">{{ customer.address }}</p>
					</div>
				</b-card>
			</b-col>
			<b-col cols="12" md="6">
				<b-card title="Chat Rekomendasi Pajak">
					<div class="chat-messages">
						<div
							v-for="(question, index) in detail.questions"
							:key="question.uuid"
							:class="`index-${index}`"
						>
							<div class="chat-message-left pb-2">
								<div class="flex-shrink-1 bg-light chat-bubles chat-round-left ml-1">
									<p class="mb-0">{{ question.text }}</p>
										<div
											v-for="comment, index in question.comments" 
											:key="index"
											class="mt-1"
										>
											<p class="mb-0">
												{{ comment.text }}
											</p>
										</div>
									<template v-if="question.replacement_contents">
										<p class="mt-1 mb-0 font-weight-bold">Profesi:</p>
										<p class="mb-0">{{ question.replacement_contents.profession }}</p>
										<template v-if="question.replacement_contents.sub_profession">
											<p class="font-weight-bold mt-1 mb-0">Subprofesi:</p>
											<ul>
												<li
													v-for="data , index in question.replacement_contents.sub_profession"
													:key="index"
												>
													<p class="mb-0 font-weight-bold">{{ data.text }}</p>
													{{ data.value.toString() }}
												</li>
											</ul>
										</template>	
										<template v-if="question.replacement_contents.administration">
											<p class="font-weight-bold mb-0">Administrasi:</p>
											<ul>
												<li
													v-for="data , index in question.replacement_contents.administration"
													:key="index"
												>
													<p class="mb-0 font-weight-bold">{{ data.text }}</p>
													{{ data.value.toString() }}
												</li>
											</ul>
										</template>
										<template v-if="question.replacement_contents.family_information">
											<p class="font-weight-bold mb-0">Info Keluarga:</p>
											<ul>
												<li
													v-for="data , index in question.replacement_contents.family_information"
													:key="index"
												>
													<p class="mb-0 font-weight-bold">{{ data.text }}</p>
													{{ data.value.toString() }}
												</li>
											</ul>
										</template>
									</template>
								</div>
							</div>

							<div class="chat-message-right pb-2" v-if="question.answer_text != null">
								<div class="flex-shrink-1 bg-primary chat-bubles chat-round-right mr-1">
									<p class="mb-0">{{ question.answer_text }}</p>
								</div>
							</div>
						</div>
					</div>
					<form v-if="lastQuestion != null">
						<div class="chat-select">
							<div v-if="lastQuestion.slug === 'profesi' || lastQuestion.slug === 'profesi_istri' || lastQuestion.slug === 'profesi_anak'">
								<p><b>{{ lastQuestion.text }}</b></p>
								<div class="form-group">
									<div
										v-for="(optionAnswer, index) in lastQuestion.options"
										:key="index"
									>
										<template v-if="optionAnswer.type == 'multiple_option'">
											<div class="custom-control custom-checkbox mb-1" v-if="index == 0">
												<input 
													class="custom-control-input" 
													type="checkbox" 
													id="parentCheck"
													:value="optionAnswer"
													v-model="multipleArray"
													@change="checkDisabled"
												>
												<label 
													class="custom-control-label" 
													for="parentCheck"
												>
													{{optionAnswer.text}}
												</label>
											</div>
											<div class="custom-control custom-checkbox" v-else>
												<input 
													type="checkbox" 
													:id="'permission_checkbox_' + optionAnswer.uuid"
													:value="optionAnswer"
													class="custom-control-input mb-2 checkbox-custom checkbox"
													v-model="multipleArray"
												>
												<label 
													class="custom-control-label text-capitalize" 
													:for="'permission_checkbox_' + optionAnswer.uuid"
												>
													{{ optionAnswer.text }}
												</label>
											</div>
										</template>
									</div>
								</div>
							</div>
							<template v-if="lastQuestion.slug === 'sub_profesi_pegawai'">
								<div
									v-for="(optionAnswer, index) in lastQuestion.options"
									:key="index"
								>
									<b-button 
										v-ripple.400="'rgba(113, 102, 240, 0.15)'"
										variant="outline-primary"
										class="w-100 mb-1"
										:value="optionAnswer.uuid"
										@click="sendButton(customer.uuid, detail.questions[detail.questions.length - 1].uuid, optionAnswer.uuid, optionAnswer.value)"
									>
										{{ optionAnswer.text }}
									</b-button>
								</div>
							</template>
							<template v-if="lastQuestion.slug === 'sub_profesi_pengusaha'">
								<h6 class="font-weight-bold">Subprofesi</h6>
								<div
									v-for="(optionAnswer, index) in lastQuestion.options"
									:key="index"
								>
									<v-select
										taggable
										multiple
										label="name"
										v-model="multipleTags"
										:options="optionSearch"
										placeholder="-- Pilih --"
										@keyup.stop.native="multipleSelect"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									/>
								</div>
							</template>
							<template v-if="lastQuestion.slug === 'sub_profesi_profesi'">
								<h6 class="font-weight-bold">Subprofesi</h6>
								<div
									v-for="(optionAnswer, index) in lastQuestion.options"
									:key="index"
								>
									<v-select
										taggable
										multiple
										label="name"
										v-model="multipleTags"
										:options="optionSearch"
										placeholder="-- Pilih --"
										@keyup.stop.native="multipleSelect"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									/>
								</div>
							</template>
							<template v-if="lastQuestion.slug == 'summary'">
								<div class="border border-primary rounded p-1">
									<h4 class="mb-0 text-center">Assesment telah berakhir!</h4>
								</div>
							</template>
							<div v-else
								v-for="optionButton, index in detail.questions[currentIndex].options"
								:key="optionButton.uuid"
							>
								<div
									v-if="optionButton.type == 'option_button' && lastQuestion.slug !== 'summary'"
								>
									<b-button 
										v-ripple.400="'rgba(113, 102, 240, 0.15)'"
										variant="outline-primary"
										class="w-100 mb-1"
										:value="optionButton.uuid"
										@click="sendButton(customer.uuid, detail.questions[detail.questions.length - 1].uuid, optionButton.uuid, optionButton.value)"
									>
										{{ optionButton.text }}
									</b-button>
								</div>
								<div>
									<template
										v-if="optionButton.type === 'multiple_option' && 
											lastQuestion.slug !== 'profesi_istri' && 
											lastQuestion.slug !== 'profesi_anak' &&
											lastQuestion.slug !== 'profesi' &&
											lastQuestion.slug !== 'sub_profesi_pengusaha' &&
											lastQuestion.slug !== 'sub_profesi_profesi'"
									>
										<div 
											class="custom-control custom-checkbox mb-1" 
											v-if="index == 0"
										>
											<input 
												type="checkbox" 
												id="parentCheck"
												:value="optionButton"
												class="custom-control-input"
												v-model="multipleArray"
												@change="checkDisabled"
											>
											<label 
												class="custom-control-label text-capitalize" 
												for="parentCheck"
											>
												{{ optionButton.text }}
											</label>
										</div>
										<div 
											class="custom-control custom-checkbox" 
											v-else
										>
											<input 
												type="checkbox" 
												:id="'permission_checkbox_' + optionButton.uuid"
												:value="optionButton"
												class="custom-control-input checkbox"
												v-model="multipleArray"
											>
											<label 
												class="custom-control-label text-capitalize" 
												:for="'permission_checkbox_' + optionButton.uuid"
											>
												{{ optionButton.text }}
											</label>
										</div>
									</template>
								</div>
							</div>
						</div>
						<div 
							class="d-flex justify-content-end mt-1" 
							v-if="lastQuestion.slug === 'profesi' || 
									lastQuestion.slug === 'harta' || 
									lastQuestion.slug === 'profesi_istri' ||
									lastQuestion.slug === 'profesi_anak'"
							>
							<b-button
								class="btn waves-effect waves-float waves-light btn-primary w-50"
								style="margin-right:8px;"
								variant="primary"
								@click.prevent="sendAnswerselect"
							>
								Lanjutkan
							</b-button>
						</div>
						<div 
							class="d-flex justify-content-end mt-1" 
							v-if="lastQuestion.slug == 'sub_profesi_pengusaha' ||
									lastQuestion.slug === 'sub_profesi_profesi'"
						>
							<b-button
								class="btn waves-effect waves-float waves-light btn-primary w-50"
								style="margin-right:8px;"
								variant="primary"
								@click.prevent="sendAnswermultiple"
							>
								Lanjutkan
							</b-button>
						</div>
					</form>

				</b-card>
			</b-col>
			<b-col cols="12">
				<a
					@click="handleBackButton()"
					variant="secondary"
					class="btn waves-effect waves-float waves-light btn-secondary"
				>
					Back
				</a>
			</b-col>

		</b-row>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import 
  { 
	BRow,
	BCol,
    BCard,
    BCardText,
	BButton,
	BSpinner,
	BFormSelect,
	BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardText,
		BButton,
		BSpinner,
		BFormSelect,
		vSelect,
		BBadge,
	},
	directives: {
		Ripple,
	},

	data() {
		return {
			detail:{},
			customer: '',
			currentIndex: 0,
			options :[],
			optionSearch :[],
			optionArray: [],
			answerArray: [],
			multipleArray: [],
			formMultiple :[],
			lastQuestion: null,
			listStaff :[],
			multipleTags: []
		}
	},

	methods: {
		handleBackButton() {
			history.back()
		},

		multipleSelect: _.debounce(function (e) {
			const keyword = e.target.value
			this.$http.get('admin/fill-questions/compliting-profile/sources/business-types?keyword='+ keyword
			).then(response => {
				this.optionSearch = response.data.data
			})
		}, 1000),

		checkDisabled() {
			var all_features = document.getElementById("parentCheck");
			var checkbox = document.getElementsByClassName("checkbox");
			for(var i=0; i<checkbox.length; i++) {
				checkbox[i].disabled = all_features.checked;
				if(all_features.checked) {
					checkbox[i].checked = false;
				}
				checkbox[i].addEventListener('change', function(e) {
					if(this.checked == true){
						all_features.disabled = true;
					} else {
						all_features.disabled = false;
					}
				});
			}
		},

		sendAnswerselect(){
			const params = this.$route.params.uuid
			let customerSet = this.customer
			let questionSet = this.detail.questions[this.detail.questions.length-1]
			let map = {
				customer_uuid: customerSet.uuid,
				question_uuid: questionSet.uuid,
			}
			const payload = new FormData()
			payload.append('customer_uuid', customerSet.uuid)
			payload.append('question_uuid', questionSet.uuid)
			let result = this.multipleArray.map((element, index) => {
				payload.append('option_uuid' + '['+ index +']', element.uuid)
				payload.append('answer' + '['+ index +']', element.value)
			})
			
			this.$http.post(`admin/fill-questions/compliting-profile/${params}/post`, payload
			).then(response => {
				this.loadDetail()
				this.multipleArray = []
			})
		},

		sendAnswermultiple(){
			const params = this.$route.params.uuid
			let customerSet = this.customer
			let lastQuestion = this.detail.questions[this.detail.questions.length-1]
			let optionSet = this.detail.questions[this.detail.questions.length-1].options[0].uuid
			let map = {
				customer_uuid: customerSet.uuid,
				question_uuid: lastQuestion.uuid,
			}
			const payload = new FormData()
			payload.append('customer_uuid', customerSet.uuid)
			payload.append('question_uuid', lastQuestion.uuid)
			let result = this.multipleTags.map((element, index) => {
				payload.append('option_uuid' + '['+ index +']', optionSet)
				payload.append('answer' + '['+ index +']', element.name)
			})
			this.$http.post(`admin/fill-questions/compliting-profile/${params}/post`, payload
			).then(response => {
				this.loadDetail()
			})
		},

		sendButton(customer, question, options, answer) { //options = 12345\\

			const params = this.$route.params.uuid
			var optionArray = []
			var answerArray = []
			optionArray.push(options)
			answerArray.push(answer)

			this.$http.post(`admin/fill-questions/compliting-profile/${params}/post`,{
				customer_uuid : customer,
				question_uuid : question,
				option_uuid: optionArray,
				answer: answerArray
			}).then(response => {
				this.loadDetail()

			})
		},
		loadSubprofession() {
			this.$http.get('admin/fill-questions/compliting-profile/sources/business-types?keyword'
			).then(response => {
				this.optionSearch = response.data.data
				
			})
		},
		loadDetail() {
			const params = this.$route.params.uuid
			this.$http.get('admin/fill-questions/compliting-profile/' + params)
				.then(response => {
					this.detail = response.data.data
					this.currentIndex = response.data.data.questions.length-1
					this.lastQuestion = response.data.data.questions[this.currentIndex]
					this.listStaff = response.data.data.questions[this.currentIndex].options
					this.customer = response.data.data.customer
					this.answer = response.data.data.questions
				})
		},
	},

	created(){
		this.loadDetail();
		this.loadSubprofession();
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
	.chat-messages {
		display: flex;
		flex-direction: column;
		max-height: 503px;
		overflow-y: scroll
	}
	.chat-message-left,
	.chat-message-right {
		display: flex;
		flex-shrink: 0
	}
	.chat-message-left {
		margin-right: auto
	}
	.chat-message-right {
		flex-direction: row-reverse;
		margin-left: auto
	}
	.flex-grow-0 {
		flex-grow: 0!important;
	}
	.border-top {
		border-top: 1px solid #dee2e6!important;
	}
	.list-group-item {
		margin: 0.75rem 0;
		cursor: pointer;
	}
	.chat-list {
		max-height: 230px;
		overflow-y: scroll;
	}
	.chat-bubles{
		padding: 18px 16px;
	}
	.chat-round-left {
		border-radius: 0px 12px 12px 12px;
	}
	.chat-round-right {
		border-radius: 12px 12px 0px 12px;
		color: white;
	}
	.chat-select {
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		padding: 16px;
		margin: 0 8px 8px;
	}
	.customer-data {
		color: #4E4F57;
		font-weight: 600;
		font-size: 20px;
		margin-bottom: 0;
	}
	.customer-title {
		font-weight: 600;
		font-size: 14px;
		color: #777884;
	}
</style>